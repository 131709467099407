
//==========prakash code =====================//
// import React, { useState, useEffect } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { DropDownUI, Calender } from "../components/Forms";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import axios from "axios";
// import Swal from 'sweetalert2';
// import { ClickButton } from "../components/Buttons";
// const product = [
//     { label: "PEPSI", value: "pepsi" },
//     { label: "BRITANNIA", value: "britannia" },
//     { label: "COLGATE", value: "colgate" },
//     { label: "LAYS", value: "lays" },
//   ];
// const DeliveryBulkUpload = () => {
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         date: '',
//         product_name: '',
//         delivery_man_login_id: "",
//     });
//     const [deliveryAgents, setDeliveryAgents] = useState([]);
//     const [loading, setLoading] = useState(true);

//     // Set the date in the format "YYYY-MM-DD"
//     const setLabel = (value, field) => {
//         setFormData({
//             ...formData,
//             [field]: moment(value).format("YYYY-MM-DD"),
//         });
//     };
//     const fetchData = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get("https://api.vsagencyvnr.com/user/getalluser", {
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": `${localStorage.getItem("token")}`,  // Add token here
//                 }
//             });
//             // Filter users where role is "deliveryagent" and map to login_id
//             const deliveryAgentUsers = response.data.users
//                 .filter(user => user.role === "deliveryagent")
//                 .map(agent => ({
//                     label: agent.login_id,  // Set login_id as label
//                     value: agent.login_id   // Set login_id as value
//                 }));
//             setDeliveryAgents(deliveryAgentUsers);
//         } catch (error) {
//             console.error("Error fetching users:", error.message);
//         } finally {
//             setLoading(false);
//         }
//     };
//     const handleSubmit = async () => {
//         setLoading(true);
//         try {
//             // Send POST request with formData in the body
//             const response = await axios.post("https://api.vsagencyvnr.com/mis/mislist", 
//                 formData,  // Send formData as the request body
//                 {
//                     headers: {
//                         "Content-Type": "application/json",
//                         "Authorization": `${localStorage.getItem("token")}`,  // Add token here
//                     }
//                 }
//             );

//             // Handle response (for example, show success message)
//             Swal.fire({
//                 title: 'Success!',
//                 text: 'Data fetched successfully!',
//                 icon: 'success',
//                 confirmButtonText: 'OK',
//             });

//             console.log(response.data); // You can process the response as needed

//         } catch (error) {
//             console.error("Error fetching mislist:", error.message);

//             // Handle error (for example, show error message)
//             Swal.fire({
//                 title: 'Error!',
//                 text: 'An error occurred while fetching data.',
//                 icon: 'error',
//                 confirmButtonText: 'OK',
//             });
//         } finally {
//             setLoading(false);
//         }
//     };
//     useEffect(() => {
//         fetchData();
//     }, []);
//     useEffect(() => {
//         if (!localStorage.getItem("userid")) {
//             navigate("/login");
//         }
//     }, []);

//     return (
//         <div>
//             <Container fluid>
//                 <div className="main my-3">
//                     <Row>
//                         <Col lg="12" md="12" xs="12">
//                             <div className="page-nav py-3 h5">Bulk Data</div>
//                         </Col>
//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="w-100">
//                                 <Calender
//                                     setLabel={(date) => setLabel(date, "date")}
//                                     selectedDate={formData.date}
//                                     calenderlabel="Date"
//                                 />
//                             </div>
//                         </Col>
//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="w-100">
//                                 <DropDownUI
//                                     optionlist={product}
//                                     placeholder="Product"
//                                     labelname="Product"
//                                     name="product_name"
//                                     value={formData.product_name}
//                                     onChange={(updatedFormData) =>
//                                         setFormData({
//                                             ...formData,
//                                             product_name: updatedFormData,
//                                         })
//                                     }
//                                 />
//                             </div>
//                         </Col>

//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="w-100">
//                                 <DropDownUI
//                                     optionlist={deliveryAgents}  // Use filtered delivery agents
//                                     placeholder="Person Id"
//                                     labelname="Person Id"
//                                     name="delivery_man_login_id"
//                                     value={formData.delivery_man_login_id}
//                                     onChange={(updatedFormData) =>
//                                         setFormData({
//                                             ...formData,
//                                             delivery_man_login_id: updatedFormData,
//                                         })
//                                     }
//                                 />
//                             </div>
//                         </Col>
//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="mt-4 justify-content-end">
//                                 <div className="px-2">
//                                     <ClickButton
//                                         label={<>SUBMIT</>}
//                                         className="create-btn"
//                                         onClick={handleSubmit}
//                                     />
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </Container>
//         </div>
//     );
// };

// export default DeliveryBulkUpload;


///===================my code========================///
import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Select, InputLabel, FormControl,CircularProgress } from "@mui/material";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import { ClickButton } from "../components/Buttons";
import { DropDownUI, Calender } from "../components/Forms";
import axiosRequest from "../service/api";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import moment from "moment";
import axios from "axios";
import { fetchAllUsers } from './Api';
const DeliveryBulkUpload = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    console.log("userData", userData);
    const [formData, setFormData] = useState({
        date: '',
        product_name: '',
        delivery_man_login_id: "",
    });
    const [filters, setFilters] = useState({
        customerName: '',
        productName: '',
        amount: ''
    });
    const [deliveryAgents, setDeliveryAgents] = useState([]);
    console.log("deliveryAgents", deliveryAgents)
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get("https://api.vsagencyvnr.com/user/getalluser", {
                headers: {
                    Authorization: `${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("responsewwe", response)

            const deliveryAgentUsers = response.data.users
                .filter(user => user.role === "deliveryagent")
                .map(agent => ({
                    label: agent.login_id,  // Set login_id as label
                    value: agent.login_id   // Set login_id as value
                }));
            setDeliveryAgents(deliveryAgentUsers);
            console.log("deliveryAgentUsers", deliveryAgentUsers)
        } catch (error) {
            console.error("Error fetching users:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const [selectedRows, setSelectedRows] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("");
    const [productStatus, setProductStatus] = useState("");
    const [loading, setLoading] = useState(true);

    console.log("vijay FormData", formData);

    useEffect(() => {
        if (!localStorage.getItem("userid")) {
            navigate("/login");
        }
    }, []);
    const [deliverysData, setDeliverysData] = useState([]);
    console.log('deliverysData', deliverysData)

    const handleSelectRow = (index) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = { ...prevSelectedRows };
            if (newSelectedRows[index]) {
                delete newSelectedRows[index];
            } else {
                newSelectedRows[index] = true;
            }
            return newSelectedRows;
        });
    };

    // const handleSubmitdelivery = async () => {
    //     setLoading(true);
    //     const selectedDeliveryData = filteredUserData.filter((item, index) => selectedRows[index]);

    //     if (selectedDeliveryData.length === 0) {
    //         Swal.fire({
    //             icon: "error",
    //             title: "No rows selected",
    //             text: "Please select at least one row to submit.",
    //             confirmButtonText: 'OK',
    //             customClass: {
    //                 confirmButton: 'my-ok-button-class',
    //             },
    //         });
    //         setLoading(false);
    //         return;
    //     }
    //     if (!paymentMethod && !productStatus) {
    //         Swal.fire({
    //             icon: "error",
    //             title: "Missing Fields",
    //             text: "Please select a Payment Method or Product Status before submitting.",
    //             confirmButtonText: 'OK',
    //             customClass: {
    //                 confirmButton: 'my-ok-button-class',
    //             },
    //         });
    //         setLoading(false);
    //         return;
    //     }        
    //     try {
    //         // Fixed denominations
    //         const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1];

    //         // Function to create case_in and case_out structure
    //         const createCaseData = () => {
    //             return denominations.map((amount) => ({
    //                 qty: 0,
    //                 amount: amount,
    //                 denomination: 0,
    //             }));
    //         };

    //         const finalProductStatus = paymentMethod === "credit" ? "delivered" : productStatus;

    //         // Generate payload for each selected delivery
    //         const payloads = selectedDeliveryData.map((item) => ({
    //             delivery_id: item.delivery.delivery_id,
    //             deliveryman: item.delivery.delivery_man,
    //             deliveryman_login_id: item.delivery.deliveryman_login_id,
    //             invoicenumber: item.invoice_number,
    //             invoicedate: item.delivery.date,
    //             customername: item.customer_name,
    //             customercode: item.customer_code,
    //             productname: item.delivery.product_name,
    //             amount: item.delivery.amount,
    //             beatcode: item.beat_code,
    //             beat_code_name: item.delivery.beat_code_name,
    //             amount_paid: item.delivery.amount_paid,
    //             case_in: createCaseData(),
    //             case_out: createCaseData(),
    //             payment_method: paymentMethod || null,
    //             product_status: finalProductStatus,
    //             due_date: null,
    //             product_condition: null,
    //             product_remarks: null,
    //             date: item.delivery.date,
    //         }));

    //         const apiURL = "https://api.vsagencyvnr.com/appdelivery/deliveryAgentUpdate";

    //         async function sendPayloads(payloads) {
    //             for (const payload of payloads) {
    //                 try {
    //                     const response = await fetch(apiURL, {
    //                         method: "PUT",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                         },
    //                         body: JSON.stringify(payload),
    //                     });

    //                     if (!response.ok) {
    //                         console.error(`Failed to send data for delivery_id: ${payload.delivery_id}`);
    //                     } else {
    //                         console.log(`Successfully sent data for delivery_id: ${payload.delivery_id}`);
    //                     }
    //                 } catch (error) {
    //                     console.error(`Error sending data for delivery_id: ${payload.delivery_id}`, error);
    //                 }
    //             }
    //         }

    //         // Call the function to send the payloads
    //         await sendPayloads(payloads);
    //         Swal.fire({
    //             icon: "success",
    //             title: "Success",
    //             text: "Delivery data updated successfully!",
    //             confirmButtonText: 'OK',
    //             customClass: { confirmButton: 'my-ok-button-class' },
    //         }).then(() => {
    //             window.location.reload();
    //         });
    //     } catch (error) {
    //         console.error("Error in API call:", error);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error",
    //             text: `Something went wrong: ${error.message}`,
    //             confirmButtonText: 'OK',
    //             customClass: {
    //                 confirmButton: 'my-ok-button-class',
    //             },
    //         });
    //     }finally {
    //         setLoading(false); // Hide spinner
      
    //     }
    
    // };
   
    const handleSubmitdelivery = async () => {
        setLoading(true);
        const selectedDeliveryData = filteredUserData.filter((item, index) => selectedRows[index]);
    
        if (selectedDeliveryData.length === 0) {
            Swal.fire({
                icon: "error",
                title: "No rows selected",
                text: "Please select at least one row to submit.",
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
            setLoading(false);
            return;
        }
    
        const payload = {
            payment_status: productStatus, // Adjust as needed
            deliveries: selectedDeliveryData.map((item) => ({
                deliveryid: item.delivery.delivery_id,
                product_name: item.delivery.product_name,
                delivery_man_login_id: item.delivery.deliveryman_login_id,
                checkbox: true, // Assuming checkbox is always true for selected rows
            })),
        };
        console.log("payload",payload);
        
        try {
            const apiURL = "https://api.vsagencyvnr.com/mis/misupdate";
    
            const response = await axios.put(apiURL, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${localStorage.getItem("token")}`,
                },
            });
    
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Delivery data updated successfully!",
                    confirmButtonText: 'OK',
                    customClass: { confirmButton: 'my-ok-button-class' },
                }).then(() => {
                    window.location.reload();
                });
            } else {
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            console.error("Error in API call:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `Something went wrong: ${error.message}`,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
        } finally {
            setLoading(false);
        }
    };
    
    const handleSubmit = async () => {
        if (!formData.delivery_man_login_id) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Field',
                text: 'Please select a Delivery Man ID.',
            });
            return;
        }

        const apiUrl = `https://api.vsagencyvnr.com/appdelivery/deliverybyroutecustomer?deliveryManLoginId=${formData.delivery_man_login_id}`;

        try {
            setLoading(true);
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            });

            // Handle successful response
            if (response.data) {
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Success',
                //     text: 'Data fetched successfully!',
                // });

                // Filter the data where delivery.payment_method is null and delivery.product_status is not "return"
                const filteredData = response.data.data.filter(item =>
                    item.delivery.payment_method === null && item.delivery.product_status !== "return"
                );

                if (filteredData.length > 0) {
                    setUserData(filteredData);
                    Swal.fire({
                        icon: 'success',
                        title: 'Your Data!',
                        text: 'Valid data has been set successfully.',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-ok-button-class',
                        },
                    });
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'No Data Avaliable',
                        text: 'No valid data found to display.',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-ok-button-class',
                        },
                    });
                }
            }
        } catch (error) {
            console.error("API Error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch data. Please try again.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
        } finally {
            setLoading(false);
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    // Filter the data based on the filters object
    const filteredUserData = userData.filter((item) => {
        return (
            (filters.customerName ? item.customer_name.includes(filters.customerName) : true) &&
            (filters.productName ? item.delivery.product_name.includes(filters.productName) : true) &&
            (filters.amount ? item.delivery.amount.toString().includes(filters.amount) : true)
        );
    });
    // const handleSubmitdelivery = async () => {
    //     // Filter selected delivery data
    //     const selectedDeliveryData = userData.filter((item, index) => selectedRows[index]);

    //     if (selectedDeliveryData.length === 0) {
    //         Swal.fire({
    //             icon: "error",
    //             title: "No rows selected",
    //             text: "Please select at least one row to submit.",
    //         });
    //         return;
    //     }

    //     try {
    //         // Fixed denominations
    //         const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1];

    //         // Function to create case_in and case_out structure
    //         const createCaseData = () => {
    //             return denominations.map((amount) => ({
    //                 qty: 0,
    //                 amount: amount,
    //                 denomination: 0,
    //             }));
    //         };
    //         const finalProductStatus = paymentMethod === "credit" ? "delivered" : productStatus;
    //         // Generate payload for each selected delivery
    //         const payloads = selectedDeliveryData.map((item) => ({
    //             delivery_id: item.delivery.delivery_id,
    //             deliveryman: item.delivery.delivery_man,
    //             deliveryman_login_id: item.delivery.deliveryman_login_id,
    //             invoicenumber: item.invoice_number,
    //             invoicedate: item.delivery.date,
    //             customername: item.customer_name,
    //             customercode: item.customer_code,
    //             productname: item.delivery.product_name,
    //             amount: item.delivery.amount,
    //             beatcode: item.beat_code,
    //             beat_code_name: item.delivery.beat_code_name,
    //             amount_paid: item.delivery.amount_paid,
    //             case_in: createCaseData(),
    //             case_out: createCaseData(),
    //             payment_method: paymentMethod,
    //             product_status: finalProductStatus,
    //             due_date: null,
    //             product_condition: null,
    //             product_remarks: null,
    //             date: item.delivery.date,
    //         }));

    //         // Make the API request
    //         const response = await fetch(
    //             "https://api.vsagencyvnr.com/appdelivery/deliveryAgentUpdate",
    //             {
    //                 method: "PUT",
    //                 body: JSON.stringify(payloads),
    //             }
    //         );
    //         console.log("Payloads for API Request:", JSON.stringify(payloads));
    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.message || "API Error");
    //         }

    //         const data = await response.json();
    //         Swal.fire({
    //             icon: "success",
    //             title: "Success",
    //             text: "Delivery data updated successfully!",
    //         });
    //         console.log("Delivery Update Response:", data);
    //     } catch (error) {
    //         console.error("Error in API call:", error);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error",
    //             text: `Something went wrong: ${error.message}`,
    //         });
    //     }
    // };

    ///========================*============//

    // const handleSubmit = async () => {
    //     if (!formData.delivery_man_login_id) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Missing Field',
    //             text: 'Please select a Delivery Man ID.',
    //         });
    //         return;
    //     }

    //     const apiUrl = `https://api.vsagencyvnr.com/appdelivery/deliverybyroutecustomer?deliveryManLoginId=${formData.delivery_man_login_id}`;

    //     try {
    //         setLoading(true);
    //         const response = await axios.get(apiUrl, {
    //             headers: {
    //                 Authorization: `${localStorage.getItem("token")}`,
    //                 "Content-Type": "application/json",
    //             },
    //         });

    //         // Handle successful response
    //         if (response.data) {
    //             Swal.fire({
    //                 icon: 'success',
    //                 title: 'Success',
    //                 text: 'Data fetched successfully!',
    //             });
    //             setUserData(response.data.data);
    //         }
    //     } catch (error) {
    //         console.error("API Error:", error);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: 'Failed to fetch data. Please try again.',
    //         });
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    return (
        <div>
            <Container fluid>
                <div className="main my-3">
                    <Row>
                        <Col lg="12" md="12" xs="12">
                            <div className="page-nav py-3 h5">Bulk Data</div>
                        </Col>
                        {/* <Col lg="2" md="6" xs="12" className="py-3">
                            <div className="w-100">
                                <Calender
                                    setLabel={(date) => setLabel(date, "date")}
                                    selectedDate={formData.date}
                                    calenderlabel="Date"
                                />
                            </div>
                        </Col>
                        <Col lg="2" md="6" xs="12" className="py-3">
                            <div className="w-100">
                                <DropDownUI
                                    optionlist={product}
                                    placeholder="Product"
                                    labelname="Product"
                                    name="product"
                                    value={formData.product}
                                    onChange={(updatedFormData) =>
                                        setFormData({
                                            ...formData,
                                            product: updatedFormData,
                                        })
                                    }
                                />
                            </div>
                        </Col> */}

                        <Col lg="2" md="6" xs="12" className="py-3">
                            <div className="w-100">
                                <DropDownUI
                                    optionlist={deliveryAgents}
                                    placeholder="Person Id"
                                    labelname="Person Id"
                                    name="delivery_man_login_id"
                                    value={formData.delivery_man_login_id}
                                    onChange={(updatedFormData) =>
                                        setFormData({
                                            ...formData,
                                            delivery_man_login_id: updatedFormData,
                                        })
                                    }
                                />
                            </div>
                        </Col>
                        <Col lg="2" md="6" xs="12" className="py-3">
                            <div className="mt-4 justify-content-end">
                                <div className="px-2">
                                    <ClickButton
                                        label={<>SUBMIT</>}
                                        className="create-btn"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {loading ? (
                        <div className="text-center py-4">
                            <CircularProgress />
                        </div>
                    ) : (
                    <Row className="mt-4">
                        
                        <Col lg="12">
                            {filteredUserData.length > 0 ? (
                                <Card>
                                    <Card.Header className="h5">Delivery Details</Card.Header>
                                    <Card.Body>
                                        <div className="mb-4">
                                            <Row>
                                                <Col lg="3" md="4" xs="12">
                                                    <TextField
                                                        label="Product Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="productName"
                                                        value={filters.productName}
                                                        onChange={handleFilterChange}
                                                    />
                                                </Col>
                                                {/* <Col lg="3" md="4" xs="12">
                                                    <TextField
                                                        label="Customer Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="customerName"
                                                        value={filters.customerName}
                                                        onChange={handleFilterChange}
                                                    />
                                                </Col> */}
                                                {/* <Col lg="3" md="4" xs="12">
                                                    <TextField
                                                        label="Amount"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="amount"
                                                        value={filters.amount}
                                                        onChange={handleFilterChange}
                                                    />
                                                </Col> */}
                                            </Row>
                                        </div>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => {
                                                                const allSelected = Object.keys(selectedRows).length === filteredUserData.length;
                                                                if (allSelected) {
                                                                    setSelectedRows({});
                                                                } else {
                                                                    const newSelectedRows = {};
                                                                    filteredUserData.forEach((_, index) => {
                                                                        newSelectedRows[index] = true;
                                                                    });
                                                                    setSelectedRows(newSelectedRows);
                                                                }
                                                            }}
                                                            checked={Object.keys(selectedRows).length === filteredUserData.length}
                                                        />
                                                    </th>
                                                    <th>Customer Name</th>
                                                    <th>Product Name</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredUserData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedRows[index] || false}
                                                                onChange={() => handleSelectRow(index)}
                                                            />
                                                        </td>
                                                        <td>{item.customer_name}</td>
                                                        <td>{item.delivery.product_name}</td>
                                                        <td>{item.delivery.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <Row>
                                            {/* <Col lg="4" md="6" xs="12">
                                                <label>Payment Method</label>
                                                <DropDownUI
                                                    optionlist={[
                                                        { label: "credit", value: "credit" },
                                                    ]}
                                                    value={paymentMethod}
                                                    onChange={(updatedValue) =>
                                                        setPaymentMethod(updatedValue)
                                                    }
                                                    placeholder="Select Payment Method"
                                                />
                                            </Col> */}
                                            <Col lg="4" md="6" xs="12">
                                                <label>Product Status</label>
                                                <DropDownUI
                                                    optionlist={[
                                                        { label: "Return", value: "return" },
                                                        { label: "Credit", value: "Partial" },
                                                  
                                                    ]}
                                                    value={productStatus}
                                                    onChange={(updatedValue) =>
                                                        setProductStatus(updatedValue)
                                                    }
                                                    placeholder="Select Product Status"
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-3">
                                            {loading ? ( // Spinner display logic
                                                <div className="text-center py-4">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <Col lg="12">
                                                    {/* UI components for displaying data */}
                                                    <ClickButton
                                                        label="Submit Delivery"
                                                        className="submit-btn"
                                                        onClick={handleSubmitdelivery}
                                                    />
                                                </Col>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : (
                                <div className="text-center">No delivery data available.</div>
                            )}
                        </Col>
                    </Row>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default DeliveryBulkUpload;

/// each wach table

// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Card, Table } from "react-bootstrap";
// import { ClickButton } from "../components/Buttons";
// import { DropDownUI, Calender } from "../components/Forms";
// import axiosRequest from "../service/api";
// import { useNavigate } from "react-router-dom";
// import Swal from 'sweetalert2';
// import moment from "moment";
// import axios from "axios";
// const DeliveryBulkUpload = () => {
//     const navigate = useNavigate();
//     const [userData, setUserData] = useState([]);
//     console.log("userData", userData);
//     const [formData, setFormData] = useState({
//         date: '',
//         product_name: '',
//         delivery_man_login_id: "",
//     });

//     const setLabel = (value, field) => {
//         setFormData({
//             ...formData,
//             [field]: moment(value).format("YYYY-MM-DD"),
//         });
//     };
//     const [deliveryAgents, setDeliveryAgents] = useState([]);
//     console.log("deliveryAgents", deliveryAgents)
//     const fetchData = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get("https://api.vsagencyvnr.com/user/getalluser", {
//                 headers: {
//                     Authorization: `${localStorage.getItem("token")}`,
//                     "Content-Type": "application/json",
//                 },
//             });
//             console.log("responsewwe", response)

//             const deliveryAgentUsers = response.data.users
//                 .filter(user => user.role === "deliveryagent")
//                 .map(agent => ({
//                     label: agent.login_id,  // Set login_id as label
//                     value: agent.login_id   // Set login_id as value
//                 }));
//             setDeliveryAgents(deliveryAgentUsers);
//             console.log("deliveryAgentUsers", deliveryAgentUsers)
//         } catch (error) {
//             console.error("Error fetching users:", error.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const [paymentMethod, setPaymentMethod] = useState("");
//     const [productStatus, setProductStatus] = useState("");
//     const [loading, setLoading] = useState(true);

//     console.log("vijay FormData", formData);

//     useEffect(() => {
//         if (!localStorage.getItem("userid")) {
//             navigate("/login");
//         }
//     }, []);
//     const [deliverysData, setDeliverysData] = useState([]);
//     console.log('deliverysData', deliverysData)


//     const handleSubmit = async () => {
//         if (!formData.delivery_man_login_id) {
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Missing Field',
//                 text: 'Please select a Delivery Man ID.',
//             });
//             return;
//         }

//         const apiUrl = `https://api.vsagencyvnr.com/appdelivery/deliverybyroutecustomer?deliveryManLoginId=${formData.delivery_man_login_id}`;

//         try {
//             setLoading(true);
//             const response = await axios.get(apiUrl, {
//                 headers: {
//                     Authorization: `${localStorage.getItem("token")}`,
//                     "Content-Type": "application/json",
//                 },
//             });

//             // Handle successful response
//             if (response.data) {
//                 // Swal.fire({
//                 //     icon: 'success',
//                 //     title: 'Success',
//                 //     text: 'Data fetched successfully!',
//                 // });

//                 // Filter the data where delivery.payment_method is null and delivery.product_status is not "return"
//                 const filteredData = response.data.data.filter(item =>
//                     item.delivery.payment_method === null && item.delivery.product_status !== "return"
//                 );

//                 if (filteredData.length > 0) {
//                     setUserData(filteredData);
//                     Swal.fire({
//                         icon: 'success',
//                         title: 'Thank You!',
//                         text: 'Valid data has been set successfully.',
//                         confirmButtonText: 'OK',
//                         customClass: {
//                             confirmButton: 'my-ok-button-class',
//                         },
//                     });
//                 } else {
//                     Swal.fire({
//                         icon: 'warning',
//                         title: 'Better Luck Next Time',
//                         text: 'No valid data found to display.',
//                         confirmButtonText: 'OK',
//                         customClass: {
//                             confirmButton: 'my-ok-button-class',
//                         },
//                     });
//                 }
//             }
//         } catch (error) {
//             console.error("API Error:", error);
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Error',
//                 text: 'Failed to fetch data. Please try again.',
//             });
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleSubmits = async (productName) => {
//         const selectedRowsData = selectedRows[productName] || {};
//         const selectedDeliveryData = userData.filter((item, index) => selectedRowsData[index]);

//         if (selectedDeliveryData.length === 0) {
//             Swal.fire({
//                 icon: "error",
//                 title: "No rows selected",
//                 text: "Please select at least one row to submit.",
//                 confirmButtonText: "OK",
//                 customClass: {
//                     confirmButton: "my-ok-button-class",
//                 },
//             });
//             return;
//         }

//         try {
//             // Call handleSubmitdelivery with relevant parameters
//             const paymentMethod = paymentMethods[productName] || null;
//             const productStatus = productStatuses[productName] || null;

//             const handleSubmitdelivery = async () => {
//                 const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1];

//                 const createCaseData = () =>
//                     denominations.map((amount) => ({
//                         qty: 0,
//                         amount: amount,
//                         denomination: 0,
//                     }));

//                 const finalProductStatus = paymentMethod === "credit" ? "delivered" : productStatus;

//                 const payloads = selectedDeliveryData.map((item) => ({
//                     delivery_id: item.delivery.delivery_id,
//                     deliveryman: item.delivery.delivery_man,
//                     deliveryman_login_id: item.delivery.deliveryman_login_id,
//                     invoicenumber: item.invoice_number,
//                     invoicedate: item.delivery.date,
//                     customername: item.customer_name,
//                     customercode: item.customer_code,
//                     productname: item.delivery.product_name,
//                     amount: item.delivery.amount,
//                     beatcode: item.beat_code,
//                     beat_code_name: item.delivery.beat_code_name,
//                     amount_paid: item.delivery.amount_paid,
//                     case_in: createCaseData(),
//                     case_out: createCaseData(),
//                     payment_method: paymentMethod,
//                     product_status: finalProductStatus,
//                     due_date: null,
//                     product_condition: null,
//                     product_remarks: null,
//                     date: item.delivery.date,
//                 }));

//                 const apiURL = "https://api.vsagencyvnr.com/appdelivery/deliveryAgentUpdate";

//                 async function sendPayloads(payloads) {
//                     for (const payload of payloads) {
//                         try {
//                             const response = await fetch(apiURL, {
//                                 method: "PUT",
//                                 headers: {
//                                     "Content-Type": "application/json",
//                                 },
//                                 body: JSON.stringify(payload),
//                             });

//                             if (!response.ok) {
//                                 console.error(
//                                     `Failed to send data for delivery_id: ${payload.delivery_id}`
//                                 );
//                             } else {
//                                 console.log(
//                                     `Successfully sent data for delivery_id: ${payload.delivery_id}`
//                                 );
//                             }
//                         } catch (error) {
//                             console.error(
//                                 `Error sending data for delivery_id: ${payload.delivery_id}`,
//                                 error
//                             );
//                         }
//                     }
//                 }

//                 await sendPayloads(payloads);

//                 Swal.fire({
//                     icon: "success",
//                     title: "Success",
//                     text: "Delivery data updated successfully!",
//                     confirmButtonText: "OK",
//                     customClass: {
//                         confirmButton: "my-ok-button-class",
//                     },
//                 });
//             };

//             await handleSubmitdelivery();
//         } catch (error) {
//             console.error("Error in submission:", error);
//             Swal.fire({
//                 icon: "error",
//                 title: "Error",
//                 text: `Something went wrong: ${error.message}`,
//                 confirmButtonText: "OK",
//                 customClass: {
//                     confirmButton: "my-ok-button-class",
//                 },
//             });
//         }
//     };

//     const [selectedRows, setSelectedRows] = useState({});
//     const [paymentMethods, setPaymentMethods] = useState({});
//     const [productStatuses, setProductStatuses] = useState({});

//     const productTables = ["pepsi", "britannia", "lays", "colgate"].map((productName) => {
//         const filteredData = userData.filter(
//             (item) => item.delivery.product_name.toLowerCase() === productName
//         );

//         const handleSelectRow = (productName, index) => {
//             setSelectedRows((prev) => ({
//                 ...prev,
//                 [productName]: {
//                     ...prev[productName],
//                     [index]: !prev[productName]?.[index],
//                 },
//             }));
//         };

//         const handleSelectAll = (productName, rowsLength) => {
//             const allSelected =
//                 Object.keys(selectedRows[productName] || {}).length === rowsLength;

//             const newSelectedRows = {};
//             if (!allSelected) {
//                 for (let i = 0; i < rowsLength; i++) {
//                     newSelectedRows[i] = true;
//                 }
//             }

//             setSelectedRows((prev) => ({
//                 ...prev,
//                 [productName]: newSelectedRows,
//             }));
//         };



//         return filteredData.length > 0 ? (
//             <div key={productName} className="mb-4">
//                 <h6 className="mt-3">{productName.toUpperCase()}</h6>
//                 <Table striped bordered hover responsive>
//                     <thead>
//                         <tr>
//                             <th>
//                                 <input
//                                     type="checkbox"
//                                     onChange={() => handleSelectAll(productName, filteredData.length)}
//                                     checked={
//                                         Object.keys(selectedRows[productName] || {}).length ===
//                                         filteredData.length
//                                     }
//                                 />
//                             </th>
//                             <th>Customer Name</th>
//                             <th>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {filteredData.map((item, index) => (
//                             <tr key={index}>
//                                 <td>
//                                     <input
//                                         type="checkbox"
//                                         checked={selectedRows[productName]?.[index] || false}
//                                         onChange={() => handleSelectRow(productName, index)}
//                                     />
//                                 </td>
//                                 <td>{item.customer_name}</td>
//                                 <td>{item.delivery.amount}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//                 <Row className="mt-3">
//                     <Col lg="4" md="6" xs="12">
//                         <label>Payment Method</label>
//                         <DropDownUI
//                             optionlist={[{ label: "Credit", value: "credit" }]}
//                             value={paymentMethods[productName] || ""}
//                             onChange={(updatedValue) =>
//                                 setPaymentMethods((prev) => ({
//                                     ...prev,
//                                     [productName]: updatedValue,
//                                 }))
//                             }
//                             placeholder="Select Payment Method"
//                         />
//                     </Col>
//                     <Col lg="4" md="6" xs="12">
//                         <label>Product Status</label>
//                         <DropDownUI
//                             optionlist={[
//                                 { label: "Return", value: "return" },
//                                 { label: "Delivered", value: "delivered" },
//                             ]}
//                             value={productStatuses[productName] || ""}
//                             onChange={(updatedValue) =>
//                                 setProductStatuses((prev) => ({
//                                     ...prev,
//                                     [productName]: updatedValue,
//                                 }))
//                             }
//                             placeholder="Select Product Status"
//                         />
//                     </Col>
//                 </Row>
//                 <div className="text-center mt-3">
//                     <ClickButton
//                         label={`Submit ${productName.toUpperCase()}`}
//                         className="submit-btn"
//                         onClick={() => handleSubmits(productName)}
//                     />

//                 </div>
//             </div>
//         ) : null;
//     });

//     return (
//         <div>
//             <Container fluid>
//                 <div className="main my-3">
//                     <Row>
//                         <Col lg="12" md="12" xs="12">
//                             <div className="page-nav py-3 h5">Bulk Data</div>
//                         </Col>


//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="w-100">
//                                 <DropDownUI
//                                     optionlist={deliveryAgents}
//                                     placeholder="Person Id"
//                                     labelname="Person Id"
//                                     name="delivery_man_login_id"
//                                     value={formData.delivery_man_login_id}
//                                     onChange={(updatedFormData) =>
//                                         setFormData({
//                                             ...formData,
//                                             delivery_man_login_id: updatedFormData,
//                                         })
//                                     }
//                                 />
//                             </div>
//                         </Col>
//                         <Col lg="2" md="6" xs="12" className="py-3">
//                             <div className="mt-4 justify-content-end">
//                                 <div className="px-2">
//                                     <ClickButton
//                                         label={<>SUBMIT</>}
//                                         className="create-btn"
//                                         onClick={handleSubmit}
//                                     />
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                     <Row className="mt-4">
//                         <Col lg="12">
//                             {userData.length > 0 ? (
//                                 <Card>
//                                     <Card.Header className="h5">Delivery Details</Card.Header>
//                                     <Card.Body>
//                                         {productTables}
//                                     </Card.Body>
//                                 </Card>
//                             ) : (
//                                 <div className="text-center">No delivery data available.</div>
//                             )}
//                         </Col>
//                     </Row>


//                 </div>
//             </Container>
//         </div>
//     );
// };

// export default DeliveryBulkUpload;

//----------------------*----------------------------//

// import React, { useState } from "react";
// import { Container, Card, Row, Col, Button, Form } from "react-bootstrap";

// const DeliveryBulkUpload = () => {
//     const [formData, setFormData] = useState({});
//     const [cards, setCards] = useState([
//         { id: 1, title: "Card 1", visible: true },
//         { id: 2, title: "Card 2", visible: true },
//         { id: 3, title: "Card 3", visible: true },
//         { id: 4, title: "Card 4", visible: true },
//         { id: 5, title: "Card 5", visible: true },
//         { id: 6, title: "Card 6", visible: true },
//         { id: 7, title: "Card 7", visible: true },
//         { id: 8, title: "Card 8", visible: true },
//         { id: 9, title: "Card 9", visible: true },
//         { id: 10, title: "Card 10", visible: true },
//         { id: 11, title: "Card 11", visible: true },
//         { id: 12, title: "Card 12", visible: true },
//     ]);
//     const [dropdownValue, setDropdownValue] = useState("");

//     const handleInputChange = (id, key, value) => {
//         setFormData((prev) => ({
//             ...prev,
//             [id]: {
//                 ...prev[id],
//                 [key]: value,
//             },
//         }));
//     };

//     const handleDropdownChange = (value) => {
//         setDropdownValue(value);
//     };

//     const handleSubmit = () => {
//         if (!dropdownValue) {
//             alert("Please select a dropdown value before submitting.");
//             return;
//         }

//         const updatedCards = cards.map((card) => {
//             if (formData[card.id]?.checkbox) {
//                 return { ...card, visible: false };
//             }
//             return card;
//         });
//         setCards(updatedCards);

//         console.log("FormData:", {
//             dropdownValue,
//             selectedCards: formData,
//         });
//     };

//     return (
//         <Container fluid>
//             <div className="main my-3">
//                 <Row className="mb-3">
//                     <Col>
//                         <Form.Group controlId="global-dropdown">
//                             <Form.Label>Action for All</Form.Label>
//                             <Form.Select
//                                 value={dropdownValue}
//                                 onChange={(e) => handleDropdownChange(e.target.value)}
//                             >
//                                 <option value="">Select Action</option>
//                                 <option value="Return">Return</option>
//                                 <option value="Credit">Credit</option>
//                             </Form.Select>
//                         </Form.Group>
//                     </Col>
//                 </Row>
//                 <Row>
//                     {cards.map((card) => (
//                         card.visible && (
//                             <Col md={4} key={card.id} className="mb-3">
//                                 <Card>
//                                     <Card.Body>
//                                         <Card.Title>{card.title}</Card.Title>
//                                         <Form>
//                                             <Form.Group controlId={`checkbox-${card.id}`} className="mb-3">
//                                                 <Form.Check
//                                                     type="checkbox"
//                                                     label="Select"
//                                                     onChange={(e) =>
//                                                         handleInputChange(
//                                                             card.id,
//                                                             "checkbox",
//                                                             e.target.checked
//                                                         )
//                                                     }
//                                                 />
//                                             </Form.Group>
//                                         </Form>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         )
//                     ))}
//                 </Row>
//                 <Row className="mt-3">
//                     <Col>
//                         <Button
//                            className="excelsubmit"
//                             onClick={handleSubmit}
//                         >
//                             Submit
//                         </Button>
//                     </Col>
//                 </Row>
//             </div>
//         </Container>
//     );
// };

// export default DeliveryBulkUpload;
