import React, { useState } from "react";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import { ClickButton } from "../components/Buttons";
import "react-toastify/dist/ReactToastify.css";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Swal from "sweetalert2";
import axios from "axios";

const product = [
  { label: "PEPSI", value: "pepsi" },
  { label: "BRITANIA", value: "britannia" },
  { label: "COLGATE", value: "colgate" },
  { label: "LAYS", value: "lays" },
];

const DeliveryDelete = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    product_name: "",
  });
  const [deliveryProduct, setDeliverProduct] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [filters, setFilters] = useState({
    invoicenumber: "",
    customername: "",
  });

  const handleSubmit = async () => {
    if (!formData.product_name) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Product!",
        confirmButtonText: 'OK',
        customClass: { confirmButton: 'my-ok-button-class' },
      })
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.vsagencyvnr.com/delivery/listbyproduct",
        {
          product_name: formData.product_name,
        }
      );
      const deliveries = response.data.deliveries || [];
      setDeliverProduct(deliveries);
      setFilteredProduct(deliveries);

      const initialSelectedRows = deliveries.reduce((acc, item, index) => {
        acc[index] = false;
        return acc;
      }, {});
      setSelectedRows(initialSelectedRows);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Delivery data List successfully!",
        confirmButtonText: 'OK',
        customClass: { confirmButton: 'my-ok-button-class' },
      })
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Something went wrong: ${error.response.data.error}`,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'my-ok-button-class',
        },
      });
      console.error("API Error:", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedSelectedRows = Object.keys(selectedRows).reduce((acc, key) => {
      acc[key] = !selectAll;
      return acc;
    }, {});
    setSelectedRows(updatedSelectedRows);
  };

  const handleRowCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [index]: !prevSelectedRows[index],
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };

      const filtered = deliveryProduct.filter(
        (item) =>
          item.invoicenumber.toLowerCase().includes(updatedFilters.invoicenumber.toLowerCase()) &&
          item.customername.toLowerCase().includes(updatedFilters.customername.toLowerCase())
      );

      setFilteredProduct(filtered);
      return updatedFilters;
    });
  };

  const handleDelete = async () => {
    const selectedData = deliveryProduct
      .filter((_, index) => selectedRows[index])
      .map((item) => ({
        delivery_id: item.deliveryid,
        productname: formData.product_name,
        checkbox: true,
      }));

    if (selectedData.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error,No rows selected for deletion",
        confirmButtonText: 'OK',
        customClass: { confirmButton: 'my-ok-button-class' },
      })
      return;
    }
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete the selected items?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      customClass: { confirmButton: 'my-ok-button-class', cancelButton: 'my-cancel-button-class' },
    });
    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await axios.delete(
          "https://api.vsagencyvnr.com/delivery/delete",
          { data: selectedData }
        );

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Delivery data Delete successfully!",
            confirmButtonText: 'OK',
            customClass: { confirmButton: 'my-ok-button-class' },
          })
          const updatedProduct = deliveryProduct.filter(
            (_, index) => !selectedRows[index]
          );
          setDeliverProduct(updatedProduct);
          setFilteredProduct(updatedProduct);
          setSelectedRows({});
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Something went wrong: ${error.message}`,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'my-ok-button-class',
          },
        });
        console.error("API Error:", error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">DeliveryDelete</div>
            </Col>

            <Col lg="3" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={product}
                  placeholder="Product"
                  labelname="Product"
                  name="product_name"
                  value={formData.product_name}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      product_name: updatedFormData,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="3" md="6" xs="12" className=" py-3 ">
              <div className="d-flex mt-4 ">
                <div className="px-2">
                  <ClickButton
                    onClick={handleSubmit}
                    label={<>SUBMIT</>}
                    className="create-btn"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="3" md="6" xs="12" className="py-3">
              <TextInputForm
                labelname="Invoice Number"
                name="invoicenumber"
                value={filters.invoicenumber}
                onChange={(e) => handleFilterChange("invoicenumber", e.target.value)}
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-3">
              <TextInputForm
                labelname="Customer Name"
                name="customername"
                value={filters.customername}
                onChange={(e) => handleFilterChange("customername", e.target.value)}
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-3">
              <div className="d-flex mt-4 ">
                <div className="px-2 ">
                  <ClickButton
                    onClick={handleDelete}
                    label={<>Delete</>}
                    className="create-btn"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              {loading ? (
                <div className="text-center py-5">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p>Loading data...</p>
                </div>
              ) :
                filteredProduct.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>Invoice Number</th>
                        <th>Customer Name</th>
                        <th>Delivery Man</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredProduct.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows[index] || false}
                              onChange={() => handleRowCheckboxChange(index)}
                            />
                          </td>
                          <td>{item.invoicenumber}</td>
                          <td>{item.customername}</td>
                          <td>{item.deliveryman}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center">No data available</div>
                )}
            </Col>

          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DeliveryDelete;
