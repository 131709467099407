import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/SideBar'
import Dashboard from './pages/Dashboard';
import './components/Table.css'
import Login from './pages/Login';
import User from './pages/User';
import CollectionAgent from './pages/CollectionAgent';
import Mis from './pages/Mis';

import ExcelUpload from './pages/ExcelUpload';
import SalesAgent from './pages/SalesAgent';
import MisUploadExcel from './pages/MisUploadExcel';
import MisManagerExcel from './pages/MisManagerExcel';
import Report from './pages/Report';
import MisSalesReEntry from './pages/MisSalesReEntry';
import { UserContext } from './context/userContext';
import Customer from './pages/Customer';
import DeliveryReEntry from './pages/DeliveryReEntry';
import CollectionPdf from './pages/CollectionPdf';
import Bank from './pages/bank';
import DeliveryBulkUpload from './pages/DeliveryBulkUpload';
import DeliveryDelete from './pages/DeliveryDelete';
function App() {
  const { user, setStatus } = useContext(UserContext)
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem('session', 'true');
    setLoggedIn(true);
    setStatus(curr => !curr)

  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  // console.log(first)
  console.log(user)

  useEffect(() => {
    if (user === null) {
      setLoggedIn(false)
      setStatus(curr => !curr)
    }
    else {
      setLoggedIn(true)
    }
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/dashboard' element={<Dashboard />} ></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/customer' element={<Customer />}></Route>
            <Route path='/console/collectionagent' element={<CollectionAgent />}></Route>
            <Route path='/console/delivery' element={<DeliveryReEntry />} />
            <Route path='/console/bank' element={<Bank/>}/>
            <Route path='/console/mis/deliverydelete' element={<DeliveryDelete/>}></Route>
            <Route path='/console/mis' element={<Mis />}></Route>
            <Route path='/console/mis/uploadexcel' element={<MisUploadExcel />} />
            <Route path='/console/mis/managerexcel' element={<MisManagerExcel />} />
            <Route path='/console/mis/salesreentry' element={<MisSalesReEntry />} />
            <Route path='/console/mis/deliverybulkupload' element={<DeliveryBulkUpload/>} />
            <Route path='/console/sales' element={<SalesAgent />} />
            <Route path='/console/uploadexcel' element={<ExcelUpload />} />
            <Route path='/console/report' element={<Report />} />
            <Route path='/console/pdf' element={<CollectionPdf/>} />
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// import React from 'react';
// import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import moment from 'moment';
// // Register Fonts
// Font.register({
//     family: 'Roboto',
//     fonts: [
//         {
//             src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf', // Regular
//         },
//         {
//             src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', // Bold
//             fontWeight: 'bold',
//         },
//     ],
// });
// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'column',
//         backgroundColor: '#FFFFFF',
//         padding: 12,
//         height: '100%',
//     },
//     title: {
//         fontSize: 24,
//         fontWeight: 'bold',
//         textAlign: 'center',
//         marginBottom: 40,
//     },
//     header: {
//         fontSize: 12,
//         fontWeight: 'bold',
//         marginBottom: 8,
//     },
//     flexRow: {
//         flexDirection: 'row',
//         marginBottom: 8,
//         alignItems: 'center',
//     },
//     flexText: {
//         fontSize: 13,
//         fontWeight: 'bold',
//         marginRight: 5,
//     },
//     flexTexts: {
//         fontSize: 12,
//         marginTop: 2,
//         marginRight: 5,
//     },
//     flexTextss: {
//         fontSize: 12,
//         marginRight: 5,
//     },
//     salestop: {
//         marginTop: 25,
//     },
//     salestopDenomination: {
//         marginTop: 15,
//     },
//     denominationRow: {
//         flexDirection: 'row',
//         width: '50%',
//         marginBottom: 4,
//         alignItems: 'center',
//         borderWidth: 1,
//         borderColor: '#000',
//         padding: 4,
//         borderRadius: 5,
//     },
//     denominationTextvalue: {
//         width: '17%',
//         fontSize: 12,
//     },
//     denominationTextcount: {
//         width: '30%',
//         fontSize: 12,
//     },
//     denominationTextanswer: {
//         width: '10%',
//         fontSize: 12,
//     },
//     denominationTextanswers: {
//         width: '40%',
//         fontSize: 12,
//     },
//     denominationInput: {
//         width: '20%',
//         fontSize: 5,
//         textAlign: 'center',
//     },
//     table: {
//         display: 'table',
//         width: 'auto',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//         marginBottom: 20, // To prevent overlap between sections
//     },
//     tableRow: {
//         flexDirection: 'row',
//     },
//     tableCol: {
//         width: '20%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableColsno: {
//         width: '12%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableColcustomer: {
//         width: '35%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableCell: {
//         margin: 5,
//         fontSize: 9,
//     },
//     footer: {
//         marginTop: 2,
//     },


//     container: {
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         width: '100%',
//     },
//     infoColumn: {
//         width: '50%',
//     },
//     pad: {
//         marginLeft: "5%"
//     },
//     partialRow: {

//         fontFamily: 'Roboto',
//         fontWeight: 'bold',
//     },
// });

// const CollectionWorkPdf = ({ totalDelivery, roll }) => {
//     console.log("roleis very", roll)
//     const deliveries = totalDelivery?.delivery || [];
//     const validPaymentMethods = ['cash', 'credit', 'gpay', 'check'];
//     const validDeliveries = deliveries.filter(delivery =>
//         delivery.payment_method && validPaymentMethods.includes(delivery.payment_method.toLowerCase())
//     );
//     const uniqueBeatCodes = Array.from(new Set(deliveries.map(delivery => delivery.beat_code_name)));
//     const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1];
//     const totalCashAmount = denominations.reduce((acc, value) => {
//         return acc + value * (totalDelivery.expenseTotalSummary[value] || 0);
//     }, 0);
//     const groupedDeliveries = deliveries.reduce((acc, delivery) => {
//         const { incharge, amount_paid } = delivery;
//         if (!acc[incharge]) {
//             acc[incharge] = { incharge, amount_paid: 0 };
//         }
//         acc[incharge].amount_paid += amount_paid;
//         return acc;
//     }, {});
//     const Expenses = totalDelivery.totalCashAmount - totalCashAmount;
//     const sortedDeliveries = Object.values(groupedDeliveries).sort((a, b) => a.incharge.localeCompare(b.incharge));
//     return (
//         <Document>
//             <Page size="A4" style={styles.page}>
//                 <View style={styles.head}>
//                     <Text style={styles.title}>{roll === 'deliveryagent' ? 'Delivery Report' : 'Sales Report'}</Text>
//                 </View>
//                 <View style={styles.container}>
//                     <View style={styles.infoColumn}>
//                         <Text style={styles.header}>VS AGENCY</Text>
//                         <Text style={styles.header}>Beat Name :{uniqueBeatCodes.join(', ')}</Text>
//                     </View>
//                     <View style={styles.infoColumn}>
//                         <View style={styles.pad}>
//                             <View style={styles.flexRow}>
//                                 <Text style={styles.flexText}>{roll === 'deliveryagent' ? 'DeliverMan :' : 'Sales Man :'}</Text>
//                                 <Text style={styles.flexTexts}>{deliveries[0]?.delivery_man || 'N/A'}</Text>
//                             </View>
//                             <View style={styles.flexRow}>
//                                 <Text style={styles.flexText}>Date:</Text>
//                                 <Text style={styles.flexTexts}>{moment(deliveries[0]?.date).format('DD-MM-YYYY')}</Text>
//                             </View>
//                             <View style={styles.flexRow}>
//                                 <Text style={styles.flexText}>Product:</Text>
//                                 <Text style={styles.flexTexts}>{deliveries[0]?.product_name || 'N/A'}</Text>
//                             </View>
//                         </View>
//                     </View>
//                 </View>
//                 {/* Table Section */}
//                 {roll === 'deliveryagent' && (
//                     <View style={styles.table} wrap={false}>
//                         <View style={styles.tableRow}>
//                             <View style={styles.tableColsno}><Text style={styles.tableCell}>S.No</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>InvoiceNumber</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerName</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerCode</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>InvoiceAmount</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CollectedAmount</Text></View>
//                             <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Receipt</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>SalesMan</Text></View>
//                         </View>
//                         {deliveries.length > 0 ? (
//                             deliveries
//                                 .sort((a, b) => (a.incharge || "").localeCompare(b.incharge || ""))
//                                 .map((delivery, index) => {
//                                     // Conditional styling for rows with payment_status 'Partial'
//                                     const rowStyle =
//                                         delivery.payment_status === 'Partial'
//                                             ? [styles.tableRow, styles.partialRow]
//                                             : styles.tableRow;
//                                     return (
//                                         <View style={[styles.tableRow, rowStyle]} key={index} wrap={false}>
//                                             <View style={styles.tableColsno}><Text style={styles.tableCell}>{index + 1}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.invoice_number}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_name}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_code}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount_paid}</Text></View>
//                                             <View style={styles.tableCol}><Text style={styles.tableCell}> {delivery.payment_method === null || '' ? "return" : delivery.payment_method === "check" ? "Cheque" : delivery.payment_method}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.receipt_number || '--'}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.incharge}</Text></View>
//                                         </View>
//                                     );
//                                 })
//                         ) : (
//                             <View style={styles.tableRow}>
//                                 <Text style={styles.tableCell}>No deliveries available</Text>
//                             </View>
//                         )}
//                     </View>
//                 )}
//                 {roll === 'salesagent' && (
//                     <View style={styles.table} wrap={false}>
//                         <View style={styles.tableRow}>
//                             <View style={styles.tableColsno}><Text style={styles.tableCell}>S.No</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>InvoiceNumber</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerName</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Amount</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerPaidAmount</Text></View>
//                             <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Receipt</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>SalesMan</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Remarks</Text></View>
//                         </View>
//                         {validDeliveries.map((delivery, index) => {
//                             console.log("Deliverysssssssssssss:", delivery);

//                             // Conditional style for rows with payment_status 'Partial'
//                             const rowStyle = delivery.payment_status === 'Partial'
//                                 ? [styles.tableRow, styles.partialRow]
//                                 : styles.tableRow;
//                             return (
//                                 <View style={[styles.tableRow, rowStyle]} key={index} wrap={false}>
//                                     <View style={styles.tableColsno}><Text style={styles.tableCell}>{index + 1}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.invoice_number}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_name}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount_paid}</Text></View>
//                                     <View style={styles.tableCol}>
//                                         <Text style={styles.tableCell}>{delivery.payment_method === null? "--": delivery.payment_method === "check" ? "Cheque": delivery.payment_method}
//                                         </Text>
//                                     </View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.receipt_number || '-'}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.user_name}</Text></View>
//                                     <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.product_remarks || '-'}</Text></View>
//                                 </View>
//                             );
//                         })}
                        
//                     </View>
//                 )}
//             </Page>
//             <Page size="A4" style={styles.page}>
//                 {/*footer section*/}
//                 <View >
//                     <View style={{ flexDirection: 'row', width: '100%' }}>
//                         <View style={{ width: '50%' }}>
//                             {/* Denomination Section */}
//                             <View style={styles.salestopDenomination}>
//                                 <Text style={styles.header}>Denomination Section</Text>
//                             </View>
//                             <View wrap={false}>
//                                 {denominations.map((value, index) => (
//                                     <View style={styles.denominationRow} key={index}>
//                                         <Text style={styles.denominationTextvalue}>{value}</Text>
//                                         <Text style={styles.denominationTextcount}> × {totalDelivery.expenseTotalSummary[value] || 0}</Text>
//                                         <Text style={styles.denominationTextanswer}>= </Text>
//                                         <Text style={styles.denominationTextanswers}>{value * (totalDelivery.expenseTotalSummary[value] || 0)}</Text>
//                                     </View>
//                                 ))}
//                             </View>
//                         </View>
//                         {roll === 'deliveryagent' && (
//                             <View style={{ width: '50%' }}>
//                                 {sortedDeliveries.length > 0 ? (
//                                     sortedDeliveries.map((delivery, index) => (
//                                         <View style={styles.tableRow} key={index} wrap={false}>
//                                             <View style={styles.tableColsno}><Text style={styles.tableCell}>{index + 1}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.incharge}</Text></View>
//                                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount_paid}</Text></View>
//                                         </View>
//                                     ))
//                                 ) : (
//                                     <View style={styles.tableRow}>
//                                         <Text style={styles.tableCell}>No deliveries available</Text>
//                                     </View>
//                                 )}
//                             </View>
//                         )}
//                     </View>
//                     {/* Summary Section */}
//                     <View style={styles.footer}>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Cash Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalCashAmount}</Text>
//                         </View>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Over All Cash Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.totalCashAmount}</Text>
//                         </View>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Gpay Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.totalGpayAmount}</Text>
//                         </View>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total cheque Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.totalCheckAmount}</Text>
//                         </View>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Credit Count :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.creditCount}</Text>
//                         </View>

//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Expenses Amount :</Text>
//                             <Text style={styles.flexTexts}>{Expenses}</Text>
//                         </View>
//                     </View>
//                 </View>
//                 {/*footer section*/}
//             </Page>
//         </Document >
//     );
// };

// export default CollectionWorkPdf;