import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ClickButton } from "../components/Buttons";
import { DropDownUI, Calender } from "../components/Forms";
import moment from "moment";
import axiosRequest from "../service/api";
import { Grid, Input, InputLabel, FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CollectionWorkPdf from "./CollectionWorkPdf";
import * as XLSX from 'xlsx';
const product = [
  { label: "PEPSI", value: "pepsi" },
  { label: "BRITANNIA", value: "britannia" },
  { label: "COLGATE", value: "colgate" },
  { label: "LAYS", value: "lays" },
];
const roll = [
  { label: "Delivery Man", value: "deliveryagent" },
  { label: "Salesman", value: "salesagent" },
];



const CollectionAgent = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: "",
    product: "",
    roll: "",
    personid: "",
    payment_method: "",
  });

  const [personId, setPersonId] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({ ...formData, [fieldName]: value });
  };

  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"),
    });
  };

  const fetchUsersByRole = async () => {
    try {
      const role = `${formData.roll}`;
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axiosRequest(
        "get",
        "user/filterbyrole",
        null,
        { role },
        { headers }
      );
      if (Array.isArray(response.users)) {
        const Login_id_array = response.users.map((user) => ({
          label: user.login_id,
          value: user.login_id,
        }));
        setPersonId(Login_id_array);
      } else {
        console.log("No users found with the specified role.");
      }
    } catch (error) {
      console.error("Error fetching users by role:", error);
    }
  };
  console.log("vijay FormData", formData);
  const [totalDelivery, setTotalDelivery] = useState([])
  console.log('totalDelivery', totalDelivery)
  const collectionAgentDataArray = async () => {

    setLoading(true);
    const { date, roll, personid, product } = formData;
    const headers = {
      Authorization: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axiosRequest(
        "get",
        "collectionagent/getdeliverymendetailsdate",
        null,
        {
          date,
          role: roll,
          loginId: personid,
          productname: product
        },
        { headers }
      );
      setTotalDelivery(response)
      if (response.message) {
        // Show information icon for response.message
        Swal.fire({
          icon: 'info',
          title: 'Information',
          text: response.message,  // Show the message from the API
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'my-ok-button-class',
        },
        });
      }
  
      if (response.msg) {
        // Show success (tick) icon for response.msg
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.msg,  // Show the msg from the API
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'my-ok-button-class',
        },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const downloadExcel = () => {
  //   // Create Sheet 1 for delivery data
  //   const deliveryData = totalDelivery.delivery.map(item => ({
  //     amount_paid: item.amount_paid,
  //     beat_code_name: item.beat_code_name,
  //     customer_name: item.customer_name,
  //     invoice_date: moment(item.invoice_date).format("YYYY-MM-DD"), // Format date
  //     payment_method: item.payment_method,
  //     product_status: item.product_status,
  //   }));

  //   const ws1 = XLSX.utils.json_to_sheet(deliveryData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");

  //   // Create Sheet 2 for case summary data
  //   const caseSummaryData = [
  //     { Description: "500", Count: totalDelivery.total_case_summery[500] || 0 },
  //     { Description: "200", Count: totalDelivery.total_case_summery[200] || 0 },
  //     { Description: "100", Count: totalDelivery.total_case_summery[100] || 0 },
  //     { Description: "50", Count: totalDelivery.total_case_summery[50] || 0 },
  //     { Description: "20", Count: totalDelivery.total_case_summery[20] || 0 },
  //     { Description: "10", Count: totalDelivery.total_case_summery[10] || 0 },
  //     { Description: "5", Count: totalDelivery.total_case_summery[5] || 0 },
  //     { Description: "2", Count: totalDelivery.total_case_summery[2] || 0 },
  //     { Description: "1", Count: totalDelivery.total_case_summery[1] || 0 },
  //   ];

  //   const ws2 = XLSX.utils.json_to_sheet(caseSummaryData);
  //   XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");

  //   // Export the workbook
  //   XLSX.writeFile(wb, "Delivery_Report.xlsx");
  // };

  {/*----*/ }

  // const downloadExcel = () => {
  //   // Create Sheet 1 for delivery data

  //   const deliveryData = totalDelivery.delivery.map(item => {
  //     return formData.roll === 'salesagent' ? {
  //       InvoiceNumber: item.invoice_number,
  //       CustomerName: item.customer_name,
  //       InvoiceAmount: item.amount,
  //       CollectedAmount: item.amount_paid,
  //       OutStandingAmount: item.remaining_balance,
  //       PaymentMethod: item.payment_method,
  //       ReceiptNumber: item.receipt_number,
  //       SalesMan: item.user_name,
  //       Remarks: item.product_remarks
  //     } : {
  //       InvoiceNumber: item.invoice_number,
  //       CustomerName: item.customer_name,
  //       CustomerCode: item.customer_code,
  //       InvoiceAmount: item.amount,
  //       CollectedAmount: item.amount_paid,
  //       OutStandingAmount: item.remaining_balance,
  //       PaymentMethod: item.payment_method || "return",
  //       ReceiptNumber: item.receipt_number,
  //       SalesMan: item.incharge,

  //     };

  //   });
  //   deliveryData.sort((a, b) => a.SalesMan.localeCompare(b.SalesMan));
  //   const ws1 = XLSX.utils.json_to_sheet(deliveryData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");

  //   // Create Sheet 2 for case summary data
  //   const caseSummaryData = [
  //     { Description: "500", Count: totalDelivery.expenseTotalSummary[500] || 0 },
  //     { Description: "200", Count: totalDelivery.expenseTotalSummary[200] || 0 },
  //     { Description: "100", Count: totalDelivery.expenseTotalSummary[100] || 0 },
  //     { Description: "50", Count: totalDelivery.expenseTotalSummary[50] || 0 },
  //     { Description: "20", Count: totalDelivery.expenseTotalSummary[20] || 0 },
  //     { Description: "10", Count: totalDelivery.expenseTotalSummary[10] || 0 },
  //     { Description: "5", Count: totalDelivery.expenseTotalSummary[5] || 0 },
  //     { Description: "2", Count: totalDelivery.expenseTotalSummary[2] || 0 },
  //     { Description: "1", Count: totalDelivery.expenseTotalSummary[1] || 0 },
  //   ];

  //   // Calculate amount for each row and create a new array with amounts
  //   const formattedCaseSummaryData = caseSummaryData.map(item => ({
  //     Description: item.Description,
  //     Count: item.Count,
  //     Amount: item.Description * item.Count, // Multiply Description by Count for Amount
  //   }));

  //   const ws2 = XLSX.utils.json_to_sheet(formattedCaseSummaryData);
  //   XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");

  //   // Export the workbook
  //   XLSX.writeFile(wb, "Delivery_Report.xlsx");
  // };

  const downloadExcel = () => {
    // Create Sheet 1 for delivery data
    const deliveryData = totalDelivery.delivery.map(item => {
      // Apply the payment method filter only when formData.roll === 'salesagent'
      if (formData.roll === 'salesagent') {
        // Define valid payment methods
        const validPaymentMethods = ['cash', 'credit', 'gpay', 'check'];

        // Only include items with valid payment_method
        if (!validPaymentMethods.includes(item.payment_method?.toLowerCase())) {
          return null; // Return null to filter out the item
        }
      }

      // Return the appropriate data for 'salesagent' and other roles
      return formData.roll === 'salesagent' ? {
        InvoiceNumber: item.invoice_number,
        CustomerName: item.customer_name,
        InvoiceAmount: item.amount,
        CollectedAmount: item.amount_paid,
        OutStandingAmount: item.remaining_balance,
        PaymentMethod: item.payment_method,
        ReceiptNumber: item.receipt_number || '-',
        SalesMan: item.user_name,
        Remarks: item.product_remarks || '-',
      } : {
        InvoiceNumber: item.invoice_number,
        CustomerName: item.customer_name,
        CustomerCode: item.customer_code,
        InvoiceAmount: item.amount,
        CollectedAmount: item.amount_paid,
        OutStandingAmount: item.remaining_balance,
        PaymentMethod: item.payment_method || "return",
        ReceiptNumber: item.receipt_number || '-',
        SalesMan: item.incharge,
      };
    }).filter(item => item !== null); // Remove null values (filtered items)

    // Sort by SalesMan
    deliveryData.sort((a, b) => a.SalesMan.localeCompare(b.SalesMan));

    const ws1 = XLSX.utils.json_to_sheet(deliveryData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");

    // Create Sheet 2 for case summary data
    const caseSummaryData = [
      { Description: "500", Count: totalDelivery.expenseTotalSummary[500] || 0 },
      { Description: "200", Count: totalDelivery.expenseTotalSummary[200] || 0 },
      { Description: "100", Count: totalDelivery.expenseTotalSummary[100] || 0 },
      { Description: "50", Count: totalDelivery.expenseTotalSummary[50] || 0 },
      { Description: "20", Count: totalDelivery.expenseTotalSummary[20] || 0 },
      { Description: "10", Count: totalDelivery.expenseTotalSummary[10] || 0 },
      { Description: "5", Count: totalDelivery.expenseTotalSummary[5] || 0 },
      { Description: "2", Count: totalDelivery.expenseTotalSummary[2] || 0 },
      { Description: "1", Count: totalDelivery.expenseTotalSummary[1] || 0 },
    ];

    // Calculate amount for each row and create a new array with amounts
    const formattedCaseSummaryData = caseSummaryData.map(item => ({
      Description: item.Description,
      Count: item.Count,
      Amount: item.Description * item.Count, // Multiply Description by Count for Amount
    }));

    const ws2 = XLSX.utils.json_to_sheet(formattedCaseSummaryData);
    XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");

    // Export the workbook
    XLSX.writeFile(wb, `${formData.product.toLowerCase()}${formData.roll === 'salesagent' ? 'salesreport' : 'deliveryreport'}.xlsx`);
  };

  useEffect(() => {
    fetchUsersByRole();
  }, [formData.roll]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      personid: "",
    }));
  }, [formData.roll]);
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);
  console.log("formData", formData.roll);
  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">Collection Data</div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <Calender
                  setLabel={(date) => setLabel(date, "date")}
                  selectedDate={formData.date}
                  calenderlabel="Date"
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={product}
                  placeholder="Product"
                  labelname="Product"
                  name="product"
                  value={formData.product}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      product: updatedFormData,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={roll}
                  placeholder="Roll"
                  labelname="Roll"
                  name="roll"
                  value={formData.roll}
                  onChange={(updatedFormData) =>
                    setFormData({ ...formData, roll: updatedFormData })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={personId}
                  placeholder="Person Id"
                  labelname="Person Id"
                  name="personid"
                  value={formData.personid}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      personid: updatedFormData,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="mt-4 justify-content-end">
                <div className="px-2">
                  <ClickButton
                    onClick={collectionAgentDataArray}
                    label={<>SUBMIT</>}
                    className="create-btn"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* Other form fields */}
            <Col lg="12" md="12" xs="12" className="mt-5">
              {totalDelivery.collectionAgentSubmisson === true ? (
                <>
                  {/* Download the PDF */}
                  <PDFDownloadLink className="mx-5"
                    document={<CollectionWorkPdf totalDelivery={totalDelivery} roll={formData.roll} />}
                    fileName={`${formData.product.toLowerCase()}${formData.roll === 'salesagent' ? 'salesreport' : 'deliveryreport'}.pdf`}
                  >
                    {({ loading }) =>
                      loading ? (
                        <ClickButton label="Loading PDF..." className="success-btn" />
                      ) : (
                        <ClickButton label="Download PDF" className="success-btn" />
                      )
                    }
                  </PDFDownloadLink>
                  {console.log("...form", formData.roll)}
                  {/* Inline preview (optional) */}
                  <BlobProvider document={<CollectionWorkPdf totalDelivery={totalDelivery} roll={formData.roll} />}>
                    {({ url }) => (
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <ClickButton label="View PDF" className="info-btn " />
                      </a>
                    )}
                  </BlobProvider>
                  {/* Excel Download Button */}
                  <span className="mx-2">
                    <ClickButton
                      onClick={downloadExcel}
                      label="Download Excel"
                      className="success-btn "
                    />
                  </span>

                </>
              ) : totalDelivery.collectionAgentSubmisson === false ? (
                <div>
                  {/* <p>No submission available at the moment.</p> */}
                  <h3>{totalDelivery.msg}</h3>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
      
    </div>
  );
};

export default CollectionAgent;

//backup

// import React from 'react';
// import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import moment from 'moment';
// // Register Fonts
// Font.register({
//     family: 'Roboto',
//     fonts: [
//         {
//             src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf', // Regular
//         },
//         {
//             src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', // Bold
//             fontWeight: 'bold',
//         },
//     ],
// });
// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'column',
//         backgroundColor: '#FFFFFF',
//         padding: 12,
//         height: '100%',
//     },
//     title: {
//         fontSize: 24,
//         fontWeight: 'bold',
//         textAlign: 'center',
//         marginBottom: 40,
//     },
//     header: {
//         fontSize: 12,
//         fontWeight: 'bold',
//         marginBottom: 8,
//     },
//     flexRow: {
//         flexDirection: 'row',
//         marginBottom: 8,
//         alignItems: 'center',
//     },
//     flexText: {
//         fontSize: 13,
//         fontWeight: 'bold',
//         marginRight: 5,
//     },
//     flexTexts: {
//         fontSize: 12,
//         marginTop: 2,
//         marginRight: 5,
//     },
//     flexTextss: {
//         fontSize: 12,
//         marginRight: 5,
//     },
//     salestop: {
//         marginTop: 25,
//     },
//     salestopDenomination: {
//         marginTop: 15,
//     },
//     denominationRow: {
//         flexDirection: 'row',
//         width: '50%',
//         marginBottom: 4,
//         alignItems: 'center',
//         borderWidth: 1,
//         borderColor: '#000',
//         padding: 4,
//         borderRadius: 5,
//     },
//     denominationTextvalue: {
//         width: '17%',
//         fontSize: 12,
//     },
//     denominationTextcount: {
//         width: '30%',
//         fontSize: 12,
//     },
//     denominationTextanswer: {
//         width: '10%',
//         fontSize: 12,
//     },
//     denominationTextanswers: {
//         width: '40%',
//         fontSize: 12,
//     },
//     denominationInput: {
//         width: '20%',
//         fontSize: 5,
//         textAlign: 'center',
//     },
//     table: {
//         display: 'table',
//         width: 'auto',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//         marginBottom: 20, // To prevent overlap between sections
//     },
//     tableRow: {
//         flexDirection: 'row',
//     },
//     tableCol: {
//         width: '20%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableColsno: {
//         width: '12%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableColcustomer: {
//         width: '35%',
//         borderStyle: 'solid',
//         borderWidth: 1,
//         borderColor: '#bfbfbf',
//     },
//     tableCell: {
//         margin: 5,
//         fontSize: 9,
//     },
//     footer: {
//         marginTop: 2,
//     },


//     container: {
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         width: '100%',
//     },
//     infoColumn: {
//         width: '50%',
//     },
//     pad: {
//         marginLeft: "5%"
//     },
//     partialRow: {

//         fontFamily: 'Roboto',
//         fontWeight: 'bold',
//     },
// });

// const ITEMS_PER_PAGE = 20; // Set the number of items per page

// const CollectionWorkPdf = ({ totalDelivery, roll }) => {
//     const deliveries = totalDelivery?.delivery || [];

//     const validPaymentMethods = ['cash', 'credit', 'gpay', 'check'];
//     const validDeliveries = deliveries.filter(delivery =>
//         delivery.payment_method && validPaymentMethods.includes(delivery.payment_method.toLowerCase())
//     );

//     const groupedDeliveries = deliveries.reduce((acc, delivery) => {
//         const { incharge, amount_paid } = delivery;
//         if (!acc[incharge]) {
//             acc[incharge] = { incharge, amount_paid: 0 };
//         }
//         acc[incharge].amount_paid += amount_paid;
//         return acc;
//     }, {});

//     // Split deliveries into pages with 20 items per page
//     const pages = [];
//     for (let i = 0; i < deliveries.length; i += ITEMS_PER_PAGE) {
//         pages.push(deliveries.slice(i, i + ITEMS_PER_PAGE));
//     }

//     return (
//         <Document>
//             {/* Loop through pages to create a new Page for each */}
//             {pages.map((pageData, pageIndex) => (
//                 <Page size="A4" style={styles.page} key={pageIndex}>
//                     <View style={styles.head}>
//                         <Text style={styles.title}>{roll === 'deliveryagent' ? 'Delivery Report' : 'Sales Report'}</Text>
//                     </View>
//                     <View style={styles.container}>
//                         <View style={styles.infoColumn}>
//                             <Text style={styles.header}>VS AGENCY</Text>
//                         </View>
//                         <View style={styles.infoColumn}>
//                             <View style={styles.pad}>
//                                 <View style={styles.flexRow}>
//                                     <Text style={styles.flexText}>{roll === 'deliveryagent' ? 'DeliverMan :' : 'Sales Man :'}</Text>
//                                     <Text style={styles.flexTexts}>{deliveries[0]?.delivery_man || 'N/A'}</Text>
//                                 </View>
//                                 <View style={styles.flexRow}>
//                                     <Text style={styles.flexText}>Date:</Text>
//                                     <Text style={styles.flexTexts}>{moment(deliveries[0]?.date).format('DD-MM-YYYY')}</Text>
//                                 </View>
//                                 <View style={styles.flexRow}>
//                                     <Text style={styles.flexText}>Product:</Text>
//                                     <Text style={styles.flexTexts}>{deliveries[0]?.product_name || 'N/A'}</Text>
//                                 </View>
//                             </View>
//                         </View>
//                     </View>

//                     {/* Table Section */}
//                     <View style={styles.table} wrap={false}>
//                         <View style={styles.tableRow}>
//                             <View style={styles.tableColsno}><Text style={styles.tableCell}>S.No</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>InvoiceNumber</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerName</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CustomerCode</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>InvoiceAmount</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>CollectedAmount</Text></View>
//                             <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Receipt</Text></View>
//                             <View style={styles.tableColcustomer}><Text style={styles.tableCell}>SalesMan</Text></View>
//                         </View>

//                         {pageData.length > 0 ? (
//                             pageData.map((delivery, index) => {
//                                 const rowStyle = delivery.payment_status === 'Partial'
//                                     ? [styles.tableRow, styles.partialRow]
//                                     : styles.tableRow;

//                                 return (
//                                     <View style={[styles.tableRow, rowStyle]} key={index} wrap={false}>
//                                         <View style={styles.tableColsno}><Text style={styles.tableCell}>{index + 1}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.invoice_number}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_name}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_code}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.amount_paid}</Text></View>
//                                         <View style={styles.tableCol}><Text style={styles.tableCell}>
//                                             {delivery.payment_method === null ? "return" : delivery.payment_method === "check" ? "Cheque" : delivery.payment_method}
//                                         </Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.receipt_number || '--'}</Text></View>
//                                         <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.incharge}</Text></View>
//                                     </View>
//                                 );
//                             })
//                         ) : (
//                             <View style={styles.tableRow}>
//                                 <Text style={styles.tableCell}>No deliveries available</Text>
//                             </View>
//                         )}
//                     </View>

//                     {/* Summary or Footer section */}
//                     <View style={styles.footer}>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Cash Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.totalCashAmount}</Text>
//                         </View>
//                         <View style={styles.flexRow}>
//                             <Text style={styles.flexText}>Total Expenses Amount :</Text>
//                             <Text style={styles.flexTexts}>{totalDelivery.totalExpensesAmount}</Text>
//                         </View>
//                     </View>
//                 </Page>
//             ))}
//         </Document>
//     );
// };

// export default CollectionWorkPdf;
